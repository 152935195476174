var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('content-section',{staticClass:"p-4",attrs:{"spacing":false}},[_c('div',{staticClass:"flex justify-items-start"},[_c('RouterLink',{staticClass:"items-center justify-center mr-4 back-button",attrs:{"to":{ name: 'ViewMailList' }}},[_c('i',{staticClass:"fas fa-arrow-left",attrs:{"aria-hidden":"true"}})]),(_vm.template)?_c('div',{staticClass:"flex flex-1 flex-wrap"},[_c('h1',{staticClass:"flex items-center font-semibold text-oDark text-28px leading-27px mb-1"},[_vm._v(" "+_vm._s(_vm.template.key)+" ")]),_c('p',{staticClass:"flex justify-items-start w-full"},[_c('span',{staticClass:"mr-36"},[_c('span',{staticClass:"font-bold mr-4"},[_vm._v("Type")]),_c('span',[_vm._v(_vm._s(_vm.template.template_type))])]),_c('span',[_c('span',{staticClass:"font-bold mr-4"},[_vm._v("Type")]),_c('span',[_vm._v(_vm._s(_vm.template.title))])])])]):_vm._e(),_c('div',{staticClass:"flex self-center"},[_c('button',{staticClass:"\n            flex\n            items-center\n            px-3\n            py-2\n            justify-center\n            cursor-pointer\n            oto-plus-bg\n            rounded\n            transition-all\n            delay-300\n            ease-linear\n            text-white\n            font-semibold\n          ",on:{"click":function($event){return _vm.onEdgeStack({ esId: _vm.addEdit.esId })}}},[_c('i',{staticClass:"fas fa-plus mr-2",attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v("Add Template")])])])],1)]),_c('content-section',{staticClass:"mt-4 py-4",attrs:{"spacing":false}},[_c('FSTable',{attrs:{"qso":_vm.qso,"fst-id":_vm.fstId,"headers":_vm.tableHeaders,"endpoint":_vm.endpoint,"text-fallback-always":true},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(item,itemIndex){return _c('FSTableRow',{key:itemIndex},[_c('FSTableRowItem',{attrs:{"col":'20%'}},[_c('span',{staticClass:"text-blue-600 cursor-pointer",on:{"click":function($event){return _vm.onEdgeStack({ esId: _vm.addEdit.esId, primaryKey: item.id })}}},[(item.language)?_c('span',{staticClass:"flex items-center"},[_c('img',{staticClass:"h-12 mr-3",attrs:{"src":_vm.getIcon(item.language.code),"alt":item.language.name}}),_c('span',[_vm._v(" "+_vm._s(item.language.name)+" ")])]):_vm._e()])]),_c('FSTableRowItem',{attrs:{"col":'40%'}},[_c('pre',[_vm._v("              "+_vm._s(item.content.length > 100
                  ? item.content.substr(0, 100)
                  : item.content)+"\n            ")])]),(item.fleet)?_c('FSTableRowItem',{attrs:{"col":'30%',"to":{
              name: 'ViewFleet',
              params: { id: item.fleet.id },
            },"text":item.fleet.name}}):_c('FSTableRowItem',{attrs:{"col":'30%',"text":"--"}}),_c('FSTableRowItem',{attrs:{"col":'10%'}},[_c('a',{attrs:{"href":""},on:{"click":function($event){return _vm.showDetails(itemIndex)}}},[_c('i',{staticClass:"fas fa-eye"})]),_c('TemplateMoreActionsDropdown',{key:item.id,attrs:{"data":item}})],1)],1)})}}])})],1),_c('TemplateAddEdit',{attrs:{"esId":_vm.addEdit.esId,"busy":_vm.addEdit.busy,"formData":_vm.addEdit.formData,"primaryKey":_vm.addEdit.primaryKey},on:{"save":function($event){return _vm.$store.dispatch('fsTable/fetchData')},"edgestack":function($event){return _vm.onEdgeStack({ esId: _vm.details.esId })}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }