<script>
import {
  defineComponent,
  watch,
  toRefs,
  computed,
  ref,
} from '@vue/composition-api'

export default defineComponent({
  name: 'TemplateDetailsLanguageDropdown',

  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    hideError: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const model = ref()
    const { value: valueRef, options: optionsRef } = toRefs(props)
    watch(valueRef, (newValue) => (model.value = newValue), {
      immediate: true,
      deep: true,
    })

    const selectedOptionCom = computed(() => {
      return (
        optionsRef.value.find(({ value }) => value === model.value) || {
          text: '--',
        }
      )
    })

    const dropdownRef = ref(null)
    function onSelect(option) {
      model.value = option.value
      //   console.log(dropdownRef)
      dropdownRef.value.doHide()
    }

    const getIcon = function(icon) {
      return require(`@/assets/img/countries/${icon}.png`)
    }

    return {
      model,
      onSelect,
      optionsRef,
      dropdownRef,
      selectedOptionCom,
      getIcon,
    }
  },
})
</script>

<template>
  <div class="w-full">
    <AppFormLabel :text="label" />
    <TDropdown
      ref="dropdownRef"
      v-model="model"
      :classes="{
        dropdown: 'origin-top-right mt-px right-0 w-full bg-white shadow',
      }"
    >
      <template
        #trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          aria-label="Country Dropdown"
          aria-haspopup="true"
          class="
            flex
            items-center
            px-3
            h-10
            text-sm
            font-medium
            text-gray-500
            rounded
            border border-gray-100
            cursor-pointer
            select-none
            hover:bg-gray-50
            group
            app-form-reset
          "
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <img
            v-if="selectedOptionCom.code"
            :src="getIcon(selectedOptionCom.code)"
            class="h-10"
            :alt="selectedOptionCom.text"
          />

          <span class="mx-2">{{ selectedOptionCom.text }}</span>
          <i class="text-sm fa fa-chevron-down akash-arrow" />
        </button>
      </template>

      <div class="overflow-y-auto py-1 max-h-44 w-full">
        <div v-for="(item, itemIndex) in optionsRef" :key="itemIndex">
          <div
            class="
              flex
              justify-between
              items-center
              py-2
              px-3
              w-full
              h-10
              text-sm text-gray-600
              cursor-pointer
              hover:bg-gray-50
            "
            @click="onSelect(item)"
          >
            <div class="flex gap-2 items-center">
              <img :src="getIcon(item.code)" class="h-10" :alt="item.text" />

              <span>{{ item.text }}</span>
            </div>

            <span
              v-if="item.value === model"
              class="
                flex
                justify-center
                items-center
                w-4
                h-4
                rounded-full
                border
              "
            >
              <i
                class="tracking-tighter leading-none text-gray-400 fas fa-check"
                style="font-size: 8px"
              >
              </i>
            </span>
          </div>
        </div>
      </div>
    </TDropdown>
  </div>
</template>
<style scoped>
.akash-arrow {
  flex: 1;
  text-align: right;
}
</style>
