<template>
  <section>
    <content-section :spacing="false" class="p-4">
      <div class="flex justify-items-start">
        <RouterLink
          :to="{ name: 'ViewMailList' }"
          class="items-center justify-center mr-4 back-button"
        >
          <i class="fas fa-arrow-left" aria-hidden="true"></i>
        </RouterLink>
        <div class="flex flex-1 flex-wrap" v-if="template">
          <h1
            class="flex items-center font-semibold text-oDark text-28px leading-27px mb-1"
          >
            {{ template.key }}
          </h1>
          <p class="flex justify-items-start w-full">
            <span class="mr-36">
              <span class="font-bold mr-4">Type</span>
              <span>{{ template.template_type }}</span>
            </span>
            <span>
              <span class="font-bold mr-4">Type</span>
              <span>{{ template.title }}</span>
            </span>
          </p>
        </div>
        <div class="flex self-center">
          <button
            class="
              flex
              items-center
              px-3
              py-2
              justify-center
              cursor-pointer
              oto-plus-bg
              rounded
              transition-all
              delay-300
              ease-linear
              text-white
              font-semibold
            "
            @click="onEdgeStack({ esId: addEdit.esId })"
          >
            <i class="fas fa-plus mr-2" aria-hidden="true"></i>
            <span>Add Template</span>
          </button>
        </div>
      </div>
    </content-section>
    <content-section class="mt-4 py-4" :spacing="false">
      <FSTable
        :qso="qso"
        :fst-id="fstId"
        :headers="tableHeaders"
        :endpoint="endpoint"
        :text-fallback-always="true"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <FSTableRow v-for="(item, itemIndex) in data" :key="itemIndex">
            <FSTableRowItem :col="'20%'">
              <span
                class="text-blue-600 cursor-pointer"
                @click="
                  onEdgeStack({ esId: addEdit.esId, primaryKey: item.id })
                "
              >
                <span class="flex items-center" v-if="item.language">
                  <img
                    :src="getIcon(item.language.code)"
                    :alt="item.language.name"
                    class="h-12 mr-3"
                  />
                  <span>
                    {{ item.language.name }}
                  </span>
                </span>
              </span>
            </FSTableRowItem>
            <FSTableRowItem :col="'40%'">
              <pre>
                {{
                  item.content.length > 100
                    ? item.content.substr(0, 100)
                    : item.content
                }}
              </pre>
            </FSTableRowItem>

            <FSTableRowItem
              v-if="item.fleet"
              :col="'30%'"
              :to="{
                name: 'ViewFleet',
                params: { id: item.fleet.id },
              }"
              :text="item.fleet.name"
            />
            <FSTableRowItem v-else :col="'30%'" text="--" />
            <FSTableRowItem :col="'10%'">
              <a href="" @click="showDetails(itemIndex)">
                <i class="fas fa-eye"></i>
              </a>
              <TemplateMoreActionsDropdown :data="item" :key="item.id" />
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>

    <TemplateAddEdit
      :esId="addEdit.esId"
      :busy="addEdit.busy"
      :formData="addEdit.formData"
      :primaryKey="addEdit.primaryKey"
      @save="$store.dispatch('fsTable/fetchData')"
      @edgestack="onEdgeStack({ esId: details.esId })"
    />
  </section>
</template>

<script>
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import TemplateMoreActionsDropdown from './MoreActionsDropdown.vue'

import TemplateAddEdit from './AddEdit.vue'
import { useEndpoints } from '@/composables'
import { useEdgeStack } from '@/plugins/edgeStack'
import { mapGetters } from 'vuex'
import { getId } from '@/utils/UrlHelper'

export default {
  name: 'TemplateDetails',
  props: {
    fstId: {
      type: String,
      default: 'templateDetails',
    },
    endpoint: {
      type: String,
      default: useEndpoints.mail.detail.index(),
    },
    qso: {
      type: Object,
      default: () => ({
        append: `&template=${getId()}`,
        prepend: '?',
      }),
    },
  },
  data() {
    return {
      tableHeaders: [
        { text: 'Language', width: '20%', sort: 'language' },
        { text: 'Content', width: '40%', sort: 'content' },
        { text: 'Fleet', width: '30%', sort: 'fleet' },
        { text: 'Action', width: '10%', sort: '' },
      ],

      addEdit: {
        esId: 'template-add-edit',

        // XStepper component
        // stepperMode: 'strict',
        // stepperStep: 1,

        // Data
        busy: false,
        rawData: null,
        formData: null,
        primaryKey: null,
      },

      details: {
        esId: 'template-details',

        busy: false,
        formData: null,
        rawData: null,
        primaryKey: null,
      },
    }
  },
  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    FSTable,
    FSTableRow,
    FSTableRowItem,
    TemplateAddEdit,
    TemplateMoreActionsDropdown,
  },
  computed: {
    ...mapGetters({
      template: 'mailTemplate/getTemplate',
    }),
  },
  methods: {
    getIcon(code) {
      return require(`@/assets/img/countries/${code}.png`)
    },
    async onEdgeStack({
      esId = this.details.esId,
      esAction = 'toggle',
      // eslint-disable-next-line no-unused-vars
      primaryKey = null,
    } = {}) {
      const edgeStack = useEdgeStack()
      this.addEdit.formData = {
        template: this.template,
        language: '',
        fleet: '',
        content: '',
      }
      // add mode needs no data, clear them out first
      if (!primaryKey && esId === this.addEdit.esId) {
        // add mode
        this.addEdit.busy = false
        this.addEdit.rawData = null
        this.addEdit.primaryKey = null
      }

      // open the edgestack first, load data asynchronally if primary key is provided
      if (esAction === 'toggle') {
        edgeStack.toggle(esId)
      } else if (esAction === 'open') {
        edgeStack.open(esId)
      } else if (esAction === 'close') {
        edgeStack.close(esId)
      } else {
        throw new Error(`Unknown esAction: ${esAction}`)
      }

      // immidiately make edgestack busy & fetch & provide data if primary key is provided
      if (primaryKey && esId === this.details.esId) {
        // view mode
        this.details.busy = true
        this.details.primaryKey = primaryKey
        await this.fetchTemplateDetails({ primaryKey })
          .then((res) => {
            this.details.rawData = res.data
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => (this.details.busy = false))
      } else if (primaryKey && esId === this.addEdit.esId) {
        // edit mode
        this.addEdit.busy = true
        this.addEdit.primaryKey = primaryKey
        await this.fetchTemplateDetails({ primaryKey })
          .then((res) => {
            const data = res.data
            const fleet = data.fleet
            const language = data.language

            data.fleet = fleet.id
            data.language = language.id

            this.addEdit.formData = data
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => (this.addEdit.busy = false))
      }
    },
    async fetchTemplateDetails({ primaryKey } = {}) {
      if (!primaryKey) {
        throw new Error('No primaryKey provided [fetchTemplate]')
      }
      const url = useEndpoints.mail.detail.details(primaryKey)
      return await this.$http.get(url)
    },
  },
}
</script>

<style lang="scss">
.oto-plus-bg {
  background-color: #2978ff;
}
.oto-plus-bg:hover {
  background-color: #226ef1;
}
.leading-27px {
  line-height: 27px;
}
</style>
