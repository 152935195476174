var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('AppFormLabel',{attrs:{"text":_vm.label}}),_c('TDropdown',{ref:"dropdownRef",attrs:{"classes":{
      dropdown: 'origin-top-right mt-px right-0 w-full bg-white shadow',
    }},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var mousedownHandler = ref.mousedownHandler;
      var focusHandler = ref.focusHandler;
      var blurHandler = ref.blurHandler;
      var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"\n          flex\n          items-center\n          px-3\n          h-10\n          text-sm\n          font-medium\n          text-gray-500\n          rounded\n          border border-gray-100\n          cursor-pointer\n          select-none\n          hover:bg-gray-50\n          group\n          app-form-reset\n        ",attrs:{"aria-label":"Country Dropdown","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[(_vm.selectedOptionCom.code)?_c('img',{staticClass:"h-10",attrs:{"src":_vm.getIcon(_vm.selectedOptionCom.code),"alt":_vm.selectedOptionCom.text}}):_vm._e(),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.selectedOptionCom.text))]),_c('i',{staticClass:"text-sm fa fa-chevron-down akash-arrow"})])]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('div',{staticClass:"overflow-y-auto py-1 max-h-44 w-full"},_vm._l((_vm.optionsRef),function(item,itemIndex){return _c('div',{key:itemIndex},[_c('div',{staticClass:"\n            flex\n            justify-between\n            items-center\n            py-2\n            px-3\n            w-full\n            h-10\n            text-sm text-gray-600\n            cursor-pointer\n            hover:bg-gray-50\n          ",on:{"click":function($event){return _vm.onSelect(item)}}},[_c('div',{staticClass:"flex gap-2 items-center"},[_c('img',{staticClass:"h-10",attrs:{"src":_vm.getIcon(item.code),"alt":item.text}}),_c('span',[_vm._v(_vm._s(item.text))])]),(item.value === _vm.model)?_c('span',{staticClass:"\n              flex\n              justify-center\n              items-center\n              w-4\n              h-4\n              rounded-full\n              border\n            "},[_c('i',{staticClass:"tracking-tighter leading-none text-gray-400 fas fa-check",staticStyle:{"font-size":"8px"}})]):_vm._e()])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }