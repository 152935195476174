<template>
  <section>
    <content-section :spacing="false">
      <FSTable
        :qso="qso"
        :fst-id="fstId"
        :headers="tableHeaders"
        :endpoint="endpoint"
        :text-fallback-always="true"
        class="fst-fixed"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <FSTableRow v-for="(item, itemIndex) in data" :key="itemIndex">
            <FSTableRowItem :col="'5%'">{{ itemIndex + 1 }}</FSTableRowItem>
            <FSTableRowItem :col="'80%'">
              <RouterLink
                :to="{
                  name: 'ViewMailTemplate',
                  params: { id: item.id },
                }"
                class="text-blue-600"
              >
                {{ item.key }}
              </RouterLink>
            </FSTableRowItem>

            <FSTableRowItem :col="'15%'">
              <div class="flex" v-if="item.available_languages.length > 0">
                <span
                  class="flex items-center"
                  v-for="(lang, i) in item.available_languages"
                  :key="i"
                >
                  <img
                    :src="icon(lang.code2)"
                    :alt="lang.name"
                    class="h-8 mr-2"
                  />
                  <span>{{ lang.name }}</span>
                </span>
              </div>
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>

    <TemplateAddEdit
      :esId="addEdit.esId"
      :busy="addEdit.busy"
      :formData="addEdit.formData"
      :vehicleOptions="addEdit.vehicleOptions"
      :primaryKey="addEdit.primaryKey"
      @save="$store.dispatch('fsTable/fetchData')"
      @edgestack="onEdgeStack({ esId: details.esId })"
    />
  </section>
</template>

<script>
import { useEndpoints } from '@/composables'
import { useEdgeStack } from '@/plugins/edgeStack'

import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'

import TemplateAddEdit from './AddEdit.vue'
// import XStatus from '@/components/badge/XStatus'

export default {
  name: 'MailTemplateList',

  props: {
    fstId: {
      type: String,
      default: 'mailTemplateList',
    },
    endpoint: {
      type: String,
      default: useEndpoints.mail.template.index(),
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
  },

  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    FSTable,
    FSTableRow,
    FSTableRowItem,
    TemplateAddEdit,
  },

  data() {
    return {
      tableHeaders: [
        { text: 'ID', width: '5%', sort: '' },
        { text: 'Template Title', width: '65%', sort: 'key' },
        { text: 'Language', width: '30%', sort: '' },
      ],

      // will be populated by generateFilterOptions()
      filterOptions: [],

      addEdit: {
        esId: 'task-addEdit',

        // XStepper component
        // stepperMode: 'strict',
        // stepperStep: 1,

        // Data
        busy: false,
        rawData: null,
        primaryKey: null,
      },

      details: {
        esId: 'task-details',

        busy: false,
        formData: null,
        vehicleOptions: null,
        primaryKey: null,
      },
    }
  },

  computed: {},

  methods: {
    icon(code) {
      return require(`@/assets/img/countries/${code}.png`)
    },
    async onEdgeStack({
      esId = this.details.esId,
      esAction = 'toggle',
      // eslint-disable-next-line no-unused-vars
      primaryKey = null,
    } = {}) {
      const edgeStack = useEdgeStack()

      // add mode needs no data, clear them out first
      if (!primaryKey && esId === this.addEdit.esId) {
        // add mode
        this.addEdit.busy = false
        this.addEdit.rawData = null
        this.addEdit.primaryKey = null
      }

      // open the edgestack first, load data asynchronally if primary key is provided
      if (esAction === 'toggle') {
        edgeStack.toggle(esId)
      } else if (esAction === 'open') {
        edgeStack.open(esId)
      } else if (esAction === 'close') {
        edgeStack.close(esId)
      } else {
        throw new Error(`Unknown esAction: ${esAction}`)
      }

      // immidiately make edgestack busy & fetch & provide data if primary key is provided
      if (primaryKey && esId === this.details.esId) {
        // view mode
        this.details.busy = true
        this.details.primaryKey = primaryKey
        await this.fetchMailTemplateDetails({ primaryKey })
          .then((res) => {
            this.details.rawData = res.data
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => (this.details.busy = false))
      } else if (primaryKey && esId === this.addEdit.esId) {
        // edit mode
        this.addEdit.busy = true
        this.addEdit.primaryKey = primaryKey
        await this.fetchMailTemplateDetails({ primaryKey })
          .then((res) => {
            const data = res.data
            const vehicle = data.vehicle

            data.user = data.user.id
            data.due_by = this.$dayjs(data.due_by).format('YYYY-MM-DD')
            data.vehicle = vehicle.id

            this.addEdit.formData = data
            this.addEdit.vehicleOptions = [
              {
                text: vehicle.qr_code,
                value: vehicle.id,
              },
            ]
            console.log('pk', this.addEdit.vehicleOptions)
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => (this.addEdit.busy = false))
      }
    },

    async fetchMailTemplateDetails({ primaryKey } = {}) {
      if (!primaryKey) {
        throw new Error('No primaryKey provided [fetchTask]')
      }
      const url = useEndpoints.task.details(primaryKey)
      return await this.$http.get(url)
    },

    formatTaskType(str = '') {
      // we'll use css for converting into title case
      return str.replaceAll(/_/g, ' ').toLowerCase()
    },
  },
}
</script>

<style lang="scss" scoped>
.refund-action {
  color: #ffffff;
  width: 87px;
  height: 25px;
  padding: 2px;
  font-weight: 400;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;

  &.refund {
    background-color: #ff5500;
  }

  &.refunded {
    color: #5f72bf;
    background-color: #d7ebff;
  }

  &.disabled {
    background-color: #bebebe;
    color: #ffffff;
  }
}
</style>
